import $ from 'jquery';

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

import 'bootstrap';

// Galería de imagenes
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import fancybox from '@fancyapps/fancybox';

// Buscador
$("#icon-search").click(function () {
    
    $("#input-group-search").removeClass('invisible'); 
    $("#input-search").focus();

});

// Obtener datos desde TWIG
document.addEventListener('DOMContentLoaded', function() {
    var blogPage = document.querySelector('#BLOG-PAGE');
    if(blogPage != null)
    {
        var blogPageId = blogPage.dataset.pageId;

        if($("#"+blogPageId).length)
        {
                var target_offset = $("#"+blogPageId).offset();
                var target_top = target_offset.top;
                $('html,body').animate({scrollTop:target_top},{duration:"slow"});
        }
    }

    var fundacio = document.querySelector('#FUNDACIO');
    if(fundacio != null)
    {
        var ref = fundacio.dataset.ref;

        if($("#"+ref).length)
        {
                var target_offset = $("#"+ref).offset();
                var target_top = target_offset.top;
                $('html,body').animate({scrollTop:target_top},{duration:"slow"});
        }
    }
        
});